<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="sendEmailOne-container">
        <!-- <div class="title">日报邮件设置</div>
        <div class="grey-line"></div> -->
        <div class="main-title">发送日报邮件</div>
        <div class="step-container">
            <el-steps style="width: 100%" :active="2" align-center>
                <el-step title="选择邮件模板" icon="el-icon-success"></el-step>
                <el-step title="邮件内容配置"></el-step>
                <el-step title="服务数据预览"></el-step>
                <el-step title="邮件内容确认"></el-step>
            </el-steps>
        </div>
        <div class="grey-line"></div>
        <div class="sendEmailOne-main">
            <el-form class="form-container" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
                <el-form-item label="选择数据日期：" prop="date">
                    <el-date-picker :picker-options="pickerOptions" class="form-input" v-model="ruleForm.date" type="date" value-format="yyyy-MM-dd" placeholder="请选择数据日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="派遣客服总数：" prop="number">
                    <el-input class="form-input" placeholder="请输入派遣客服总数" v-model="ruleForm.number"></el-input>
                </el-form-item>
                <el-form-item label="客服分配设置：" :rules="[{ required: true, message: ' ', trigger: 'change' }]">
                    <el-table class="common-table" style="width: 98%;margin: 0" border :data="ruleForm.shopList">
                        <el-table-column prop="platformName" label="平台" :formatter="tableColumn"></el-table-column>
                        <el-table-column prop="shopName" label="店铺" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="服务客服数量" :formatter="tableColumn">
                            <template slot-scope="scope">
                                <el-form-item :rules="[{ validator: decimalNull, message: ' ', trigger: 'change' }, { required: true, message: '请选择输入派遣客服总数量', trigger: 'change' }]" :prop="`shopList[${scope.$index}].number`">
                                    <el-input placeholder="请输入派遣客服数量" v-model.trim="scope.row.number"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="服务部门设置:">
                    <div class="add-btn" @click="() => { addDepartFun() }"><i class="el-icon-plus"></i> 新增部门</div>
                    <el-table v-if="ruleForm.shopTable && ruleForm.shopTable.length" :span-method="objectSpanMethod" class="common-table" style="width: 98%;margin: 0" border :data="ruleForm.shopTable">
                        <el-table-column label="部门" prop="departmentName" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="平台" prop="platformName" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="店铺名" prop="shopName" :formatter="tableColumn"></el-table-column>
                        <el-table-column label="操作" :formatter="tableColumn">
                            <template slot-scope="scope">
                                <el-button type="text" @click="() => { addDepartFun(scope.row.departmentName, scope.row.id) }">编辑</el-button>
                                <el-button type="text" @click="() => { deleteDepart(scope.row.id) }">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
        <div class="grey-line"></div>
        <div class="sendEmailOne-bottom">
            <el-button class="common-screen-btn" type="primary" @click="() => { addSerDis() }">下一步：服务数据预览</el-button>
            <el-button class="common-screen-btn" plain @click="() => { $router.push(`/mail/setting`) }">上一步：返回模板列表</el-button>
        </div>
        <el-dialog v-dialogDrag title="新增部门" class="add-dialog" :append-to-body="true" :visible.sync="addVisible" @close="() => { departCancel() }">
            <el-form class="form-container" :model="departForm" ref="departForm" label-width="140px">
                <el-form-item label="部门名称：" :rules="[{ required: true, message: '请输入部门名称', trigger: 'change' }]" prop="departmentName">
                    <el-input style="width: 100%" placeholder="请输入部门名称" v-model="departForm.departmentName"></el-input>
                </el-form-item>
                <el-form-item label="部门服务店铺：" :rules="[{ required: true, message: ' ', trigger: 'change' }]">
                    <div class="depart-shop">
                        <span class="shopItem" v-for="(item, index) in checkedShop" :key="index">{{item.name}} <i class="el-icon-circle-close" @click="() => { deleteChecked(item) }"></i></span>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div style="border: 1px solid #bbb;">
                        <div class="shop-title">店铺列表</div>
                        <div class="chooseShop-container">
                            <div class="chooseShop-left">
                                <div :class="{'leftItem': true, 'leftItem-active': platActiveId == item.platformId}" v-for="(item) in platList" :key="item.platformId" @click="() => { platActiveFun(item.platformId) }">{{item.platformName}}</div>
                            </div>
                            <div class="chooseShop-right">
                                <el-checkbox class="checkboxAll" v-model="checkedAll" @change="(val) => { chooseAllFun(val) }">全选</el-checkbox>
                                <div v-for="(item, index) in shopListAll" :key="index">
                                    <el-checkbox v-if="item.platformId == platActiveId && !item.isToDepart" v-model="item.isChoose" @change="(val) => { chooseShopFun(val, item) }" :label="item.shopName"></el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="() => { departCancel() }">取 消</el-button>
                <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => { departIsOk() }">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { custShopList, emailAddDepart, emailEditDepart, emailDepartList, deleteDepart, addSerDis, serviceDis } from "../../service/dataInfo.js"
import { tableColumn, duplicateRemoval, decimalNull } from "../../utils/index.js"
export default {
    data() {
        return {
            loading: false,
            btnLoading: false,
            tableColumn,
            decimalNull,
            modelId: this.$route.query.modelId, // 模板id
            modelName: this.$route.query.modelName, // 模板id
            date: this.$route.query.date, // 模板id
            addVisible: false, // 新增部门弹窗
            platList: [], // 部门列表
            platActiveId: '', // 选中的平台id
            shopListAll: [], // 所有平台的店铺
            checkedShop: [], // 选中的店铺
            checkedAll: false, // 全选按钮
            ruleForm: {
                shopList: [], // 
                shopTable: [], // 部门列表
                date: ''
            },
            rules: { // 校验
                date: [{ required: true, message: "请选择日期", trigger: "change" }],
                number: [{ validator: decimalNull, trigger: "change" }, { required: true, message: "请选择输入派遣客服总数量", trigger: "change" }],
                // toEmail: [{ required: true, message: "请填写邮箱地址", trigger: "change" }, { type: 'email', message: '请输入正确的邮箱地址', trigger: 'change' }],
                // conte: [{ required: true, message: "请填写内容", trigger: "change" }],
            },
            departForm: { // 部门参数
                departmentName: '',
            },
            departmentName: '',
            departmentId: '',
            pickerOptions: { // 禁用以后的时间
                disabledDate: (time) => {
                    return time.getTime() > new Date().getTime();
                }
            },
        }
    },
    mounted () {
        if (this.$route.query.date) {
            this.ruleForm.date = this.$route.query.date
        }
        this.custShopList().then(() => {
            this.emailDepartList()
            this.serviceDis()
        })
    },
    methods: {
        deleteChecked (item) { // 部门-删除选中的店铺
            let checkedShop = JSON.parse(JSON.stringify(this.checkedShop))
            let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
            checkedShop = checkedShop.filter((shopItem) => {
                return shopItem.shopId != item.shopId
            })
            shopListAll.map((shopItem) => {
                if (shopItem.shopId == item.shopId) {
                    shopItem.isChoose = false
                }
            })
            this.checkedShop = checkedShop
            this.shopListAll = shopListAll
        },
        platActiveFun (platformId) { // 选择部门-控制全选按钮
            this.platActiveId = platformId
            let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
            let isAll = true
            shopListAll.map(( item ) => {
                if (item.platformId == platformId && !item.isToDepart && !item.isChoose) {
                    isAll = false
                }
            })
            this.checkedAll = isAll
        },
        async serviceDis () { // 客服分配设置详情
            let { data } = await serviceDis({ emailModelId: this.modelId })
            if (data && data.length) {
                let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                let serviceNumber = ''
                ruleForm.shopList.map((item) => {
                    data.map((itemTwo) => {
                        if (item.shopId == itemTwo.shopId) {
                            item.number = itemTwo.number
                            serviceNumber = itemTwo.serviceNumber
                        }
                    })
                })
                ruleForm.number = serviceNumber
                this.ruleForm = ruleForm
            }
        },
        async addSerDis() { // 客服分配设置
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) { // 发送请求
                    let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                    let shopList = []
                    if (ruleForm.shopList && ruleForm.shopList.length) {
                        ruleForm.shopList.map((item) => {
                            shopList.push({ shopId: item.shopId, number: item.number })
                        })
                    }
                    let params = {
                        serviceNumber: ruleForm.number,
                        emailModelId: this.modelId,
                        shopList: shopList
                    }
                    console.log(params)
                    this.loading = true
                    let { code } = await addSerDis(params)
                    this.loading = false
                    if (code == 200) {
                        this.$message.success("提交成功")
                        this.$router.push(`/mail/setting/sendEmailTwo?modelId=${this.modelId}&date=${ruleForm.date}&modelName=${this.modelName}`)
                    }
                } else {
                    this.$message.error("请填写完整的信息");
                }
            });
            
        },
        departCancel () { // 部门取消按钮/
            if (this.departmentName) {
                this.emailDepartList()
            }
            this.addVisible = false
        },
        async deleteDepart (id) { // 删除部门
            this.loading = true
            let deleteParams = {
                id: id,
                emailModelId: this.modelId
            }
            let { code } = await deleteDepart(deleteParams)
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("删除成功")
                this.emailDepartList()
            }
        },
        async emailDepartList () { // 获取部门列表
            let { data } = await emailDepartList({ emailModelId: this.modelId })
            let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
            let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
            ruleForm.shopTable = []
            shopListAll.map((itemShopAll) => {
                itemShopAll.isToDepart = false
                itemShopAll.isChoose = false
                delete itemShopAll.departmentName
            })
            if ( data && data.length ) {
                data.map(( item ) => {
                    if (item.shopIds) {
                        let shopIds = item.shopIds.split(',')
                        shopIds.map((itemShopId, indexShopId) => {
                            shopListAll.map((itemShopAll) => {
                                if (itemShopId == itemShopAll.shopId) {
                                    itemShopAll.isToDepart = true
                                    itemShopAll.departmentName = item.departmentName
                                    if (indexShopId == 0) {
                                        // ruleForm.shopTable.push(Object.assign(itemShopAll, { id: item.id, spanRow: shopIds.length }))
                                        ruleForm.shopTable.push({...itemShopAll, id: item.id, spanRow: shopIds.length })
                                    } else {
                                        // ruleForm.shopTable.push(Object.assign(itemShopAll, { id: item.id }))
                                        ruleForm.shopTable.push({...itemShopAll, id: item.id })
                                    }
                                }
                            })
                        })
                    }
                })
            }
            this.ruleForm = ruleForm
            this.shopListAll = shopListAll
            console.log(shopListAll)
        },
        objectSpanMethod({ row, columnIndex, column }) { // 合并表格
            if (columnIndex == 0 || column.label == '操作') {
                if (row.spanRow) {
                    return {
                    rowspan: row.spanRow,
                    colspan: 1
                    };
                } else {
                    return {
                    rowspan: 0,
                    colspan: 0
                    };
                }
            }
        },
        departIsOk () { // 确认部门
            this.$refs['departForm'].validate(async (valid) => {
                if (valid) { // 发送请求
                    let departForm = JSON.parse(JSON.stringify(this.departForm))
                    let checkedShop = JSON.parse(JSON.stringify(this.checkedShop))
                    let shopTable = JSON.parse(JSON.stringify(this.ruleForm.shopTable))
                    let departmentName = this.departmentName
                    let shopIds = []
                    checkedShop.map((item) => {
                        shopIds.push(item.shopId)
                    })
                    if (!checkedShop || !checkedShop.length) {
                        return this.$message.error('请选择店铺')
                    }
                    if ( departForm.departmentName ) {
                        let isRepeat = false
                        shopTable.map((item) => {
                            console.log(item.departmentName, departForm.departmentName)
                            if (item.departmentName == departForm.departmentName) {
                                isRepeat = true
                            }
                        })
                        if (isRepeat) {
                            return this.$message.error("部门名称已存在，请重新填写")
                        }
                    }
                    let commitParams = {
                        departmentName: departForm.departmentName,
                        emailModelId: this.modelId,
                        shopIds: shopIds.join(',')
                    }
                    let res
                    this.btnLoading = true
                    if (!departmentName) {
                        res = await emailAddDepart(commitParams)
                    } else {
                        commitParams.id = this.departmentId
                        res =  await emailEditDepart(commitParams)
                    }
                    this.btnLoading = false
                    if ( res.code == 200 ) {
                        if (!departmentName) {
                            this.$message.success("添加成功")
                        } else {
                            this.$message.success("编辑成功")
                        }
                        this.emailDepartList()
                        this.addVisible = false
                    }
                } else {
                    this.$message.error("请填写完整的信息");
                }
            });
        },
        addDepartFun(departmentName, departmentId) { // 新增/编辑部门
            if (!departmentName) {
                let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
                // console.log(shopListAll)
                shopListAll.map((item, index) => {
                    if (index == 0) {
                        this.platActiveId = item.platformId || ''
                    }
                    item.isChoose = false
                })
                this.shopListAll =  shopListAll
                this.checkedShop =  []
                this.checkedAll = false
                this.departmentName = ''
                this.departmentId = ''
                this.departForm ={ // 部门参数
                    departmentName: '',
                }
            } else {
                let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
                let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                let checkedShop =  []
                let isAllChecked =  true
                let platActiveId =  ''
                
                shopListAll.map((item, index) => {
                    if (index == 0) {
                        platActiveId = item.platformId || ''
                    }
                    item.isChoose = false
                    if (item.departmentName == departmentName) {
                        item.isChoose = true
                        checkedShop.push({ ...item, name: `${item.platformName}-${item.shopName}` })
                        item.isToDepart = false
                    }
                    if (platActiveId == item.platformId && !item.isChoose) {
                        isAllChecked =  false
                    }
                })
                
                this.shopListAll =  shopListAll
                this.checkedShop =  checkedShop
                this.platActiveId =  platActiveId
                this.checkedAll = isAllChecked

                this.departmentName = departmentName
                this.departmentId = departmentId
                this.departForm ={ // 部门参数
                    departmentName: departmentName
                }
                ruleForm.shopTable = ruleForm.shopTable.filter((item) => {
                    console.log(item.departmentName,departmentName)
                    return item.departmentName != departmentName
                })
                this.ruleForm =  ruleForm
            }
            
            this.addVisible = true
        },
        chooseAllFun(val) { // 全选
            let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
            let checkedShop = []
            // let checkedShop = JSON.parse(JSON.stringify(this.checkedShop))
            let platActiveId = this.platActiveId
            shopListAll.map((item) => {
                if (platActiveId == item.platformId && !item.isToDepart) {
                    item.isChoose = val
                }
                if (item.isChoose) {
                    checkedShop.push({...item, name: `${item.platformName}-${item.shopName}`})
                }
            })
            this.shopListAll = shopListAll
            this.checkedShop = checkedShop
        },
        chooseShopFun(val, row) { // 选择店铺
            let checkedShop = JSON.parse(JSON.stringify(this.checkedShop))
            let shopListAll = JSON.parse(JSON.stringify(this.shopListAll))
            let platActiveId = this.platActiveId
            if (val) {
                checkedShop.push({...row, name: `${row.platformName}-${row.shopName}`})
            } else {
                checkedShop = checkedShop.filter((item) => {
                    return item.shopId != row.shopId
                })
            }
            let isHaveAll = true
            shopListAll.map((item) => {
                if (platActiveId == item.platformId && !item.isChoose ) {
                    isHaveAll = false
                }
            })
            this.checkedAll = isHaveAll
            this.checkedShop = checkedShop
        },
        async custShopList() { // 获取模板的店铺
            let { data } = await custShopList({ id: this.modelId })
            let platList = []
            if (data && data.length) {
                data.map((item, index) => {
                    if(index == 0) {
                        this.platActiveId = item.platformId
                    }
                    item.number = ''
                    item.isChoose = false
                    item.isToDepart = false
                    platList.push({ platformId: item.platformId, platformName: item.platformName })
                })
            }

            this.platList = duplicateRemoval(platList, 'platformId')
            this.shopListAll = data || []
            this.ruleForm.shopList = data || []
        }
    }
}
</script>
<style lang="less" scoped>
.sendEmailOne-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    padding-top: 0;
    box-sizing: border-box;
    .add-btn {
        height: 38px;
        line-height: 38px;
        border: 1px solid #1890ff;
        padding: 0 8px 0 8px;
        color: #1890ff;
        display: inline-block;
        border-radius: 4px;
        margin-bottom: 16px;
        cursor: pointer;
    }
    .form-container {
        width: 100%;
        margin: 32px 0;
    }
    .form-input {
        width: 400px;
    }
    .step-container {
        height: 117px;
        display: flex;
        align-items: center;
        /deep/.el-step__title.is-process {
            color: #C0C4CC;
            font-weight: 500 !important;
        }
        /deep/.el-step__icon.is-text {
            color: #C0C4CC;
            border-color: #C0C4CC;
        }
        /deep/.el-step__head.is-finish .is-text {
            color: #409EFF !important;
            border-color: #409EFF !important;
        }
    }
    /deep/.el-table--border th {
        background: #f2f4f5;
        padding: 0;
    }
    .sendEmailOne-bottom {
        height: 64px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding-top: 16px;
    }
    .sendEmailOne-main {
        flex: 1;
        overflow: auto;
        overflow-x: hidden;
    }
    .main-title {
        box-sizing: border-box;
        padding: 16px 16px 22px;
        height: 72px;
        color: #101010;
        font-size: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    .title {
        box-sizing: border-box;
        padding: 6px 16px 22px;
        height: 56px;
        color: #333;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::after {
            display: flex;
            content: ' ';
            position: absolute;
            height: 1px;
            width: 120%;
            background: #f2f4f5;
            bottom: 0;
            left: -10%;
        }
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
}
.add-dialog {
    // width: 560px;
    /deep/.el-dialog {
        width: 620px;
    }
    .chooseShop-container {
        width: 100%;
        display: flex;
        height: 240px;
        .chooseShop-left {
            width: 120px;
            border-right: 1px solid #bbb;
            overflow-y: auto;
            .leftItem {
                height: 40px;
                line-height: 40px;
                width: 100%;
                margin-top: 8px;
                color: #101010;
                font-size: 14px;
                text-indent: 32px;
                background: #fff;
                cursor: pointer;
            }
            .leftItem-active {
                background: #1890ff;
                color: #fff;
            }
        }
        .chooseShop-right {
            flex: 1;
            overflow: auto;
            padding-left: 32px;
            box-sizing: border-box;
            // /deep/.el-checkbox {
            //     line-height: 24px;
            // }
            .checkboxAll {
                margin-left: -16px;
            }
        }
    }
    .shop-title {
        height: 48px;
        line-height: 48px;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        text-indent: 16px;
        background: #f7f7f7;
        border-bottom: 1px solid #bbb;
    }
    .depart-shop {
        height: 120px;
        width: 100%;
        overflow-x: auto;
        border-radius: 4px;
        border: 1px solid #bbb;
        padding: 8px;
        padding-left: 0;
        box-sizing: border-box;
        line-height: 36px;
        .shopItem {
            padding: 6px 8px;
            border-radius: 4px;
            border: 1px solid #1890ff;
            margin-left: 8px;
            color: #1890ff;
            font-size: 14px;
            vertical-align: top;
            display: inline-block;
            line-height: 1;
            .el-icon-circle-close {
                margin-left: 8px;
                cursor: pointer;
            }
        }
    }
}
</style>